.btn {
  border-radius: 0;
  font-family: $primary-font;
  font-size: 18px;
  font-weight: 700;
  padding: 12px 20px;
  max-width: 100%;

  &.blue {
    background-color: $brand-primary;
    color: $off-white;

    &:hover {
      background-color: lighten($brand-primary, 15);
    }
  }

  &.brown {
    background-color: $brown;
    color: $black;

    &:hover {
      background-color: $brand-primary;
      color: $white;
    }
  }
}
