.banner {
  background-color: $light-brown;
  border-top: 4px solid $brand-primary;

  .header {
    padding: 25px 0;

    @include desktop {
      padding: 20px 0;
    }

    .logo {
      width: 270px;

      @include tablet {
        width: 319px;
      }
    }
  }

  .nav-primary-container {
    background-color: $brown;
    min-height: 5px;
  }

  .nav-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      a {
        font-family: $secondary-font;
        color: $brand-primary;
        @include letter-spacing(0);
      }
    }

    &.top {
      margin: 10px 0 0;
      text-align: right;

      li {
        display: inline-block;
        margin: 0 0 0 40px;

        &:first-child {
          margin: 0;
        }

        a {
          font-size: 15px;
        }

        a:hover, &.current-menu-item a {
          border-bottom: 1px solid $brand-primary;
        }

        &.phone a:hover {
          border: 0;
        }
      }
    }

    &.primary {
      display: flex;
      justify-content: space-between;

      li {
        @include flex(0,0,auto);
        text-align: center;

        a {
          display: block;
          padding: 10px 15px;
          font-size: 15px;

          @include wide {
            padding: 10px 20px;
            font-size: 16px;
          }
        }

        a:hover, &.current-menu-item a {
          color: $brown;
          background-color: $brand-primary;
        }
      }
    }
  }
}


.hero {
  height: 350px;
  background-image: url('../images/hero_home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include desktop {
    height: 500px;
  }

  @include hd {
    height: 627px;
  }

  &.interior-hero {
    height: 225px;

    @include desktop {
      height: 375px;
    }

    @include hd {
      height: 450px;
    }
  }

  &.no-hero {
    display: none;
  }
}


.strapline {
  position: relative;
  padding: 30px 0 40px;
  background-color: $brown;
  text-align: center;

  @include tablet {
    text-align: left;
  }

  @include laptop {
    padding: 35px 0;
  }

  .btn {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    margin: 15px 0 0;

    @include laptop {
      font-size: 21px;
    }

    @include desktop {
      float: right;
      margin: 5px 0 0;
    }
  }

  .heading {
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 12px;
    @include letter-spacing(0);

    @include laptop {
      font-size: 41px;
      line-height: 41px;
    }
  }

  .content {
    margin: 0;
    line-height: 24px;
  }
}
