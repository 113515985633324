html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: 28px;

  @include laptop {
    font-size: $h1-size;
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: 20px;

  @include laptop {
    font-size: $h2-size;
  }
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
}

p, li {
  font-family: $body-font;
  font-size: 16px;
  font-weight: $body-font-weight;
  @include letter-spacing(50);
  line-height: 19px;

  @include laptop {
    line-height: 21px;
    font-size: $body-font-size;
  }
}

a {
  transition: $base-transition;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.heading {
  font-family: $secondary-font;
  color: $brand-primary;
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

.panel {
  padding: $section-padding 0;

  h1 {
    text-align: center;
    margin: 0 0 20px;
  }

  .sub-text {

    @include desktop {
      padding: 0 50px;
    }
  }

  .btn {
    width: 290px;
  }
}


.content-card {
  background-color: $white;
  padding: 20px;

  p {
    font-size: 16px;
    font-weight: 400;
    @include letter-spacing(0);
  }
}

.card-block {
  padding: 0 15px;
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;

  @include desktop {
    margin: 30px 0 0;
  }

  .image {
    position: relative;
    width: 100%;
    height: 255px;
    background-color: $brand-primary;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      @include stretch;
      background-color: rgba($black, .4);
      content: '';
      opacity: 0;
      transition: $base-transition;
    }

    &.placeholder {
      background-color: $brown;

      &:before {
        content: 'No Preview Available';
        opacity: 1;
        color: $brand-primary;
        background-color: transparent;
      }
    }
  }

  .content-card {
    transition: $base-transition;
    @include flex(1,1,auto);

    h2, p {
      transition: $base-transition;
    }

    h2 {
      color: $black;
      margin: 0;
    }

    p {
      margin: 10px 0 0;
    }
  }

  &:hover {

    .image:before {
      opacity: 1;
    }

    .content-card {
      background-color: $brand-primary;

      h2, p {
        color: $white;
      }
    }
  }
}


.card-list {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .card-block {
    @include flex(0,0,100%);

    @include tablet {
      @include flex(0,0,50%)
    }

    @include desktop {
      @include flex(0,0,33.3%)
    }
  }
}


.slick-slider {

  .slick-dots {

    li button:before {
      font-size: 14px;
    }
  }

  .slick-slide {
    outline: none;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 48px;
    color: #5f5f5f;
    cursor: pointer;
    transition: $base-transition;

    &:hover {
      color: $brand-primary;
    }

    &.next {
      right: -50px;
    }

    &.prev {
      left: -50px;
    }
  }
}


@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {
  // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $modifier in (pull, push) {
      @for $i from 0 through $grid-columns {
        .#{$modifier}#{$infix}-#{$i} {
          @include make-col-modifier($modifier, $i, $grid-columns)
        }
      }
    }
    @for $i from 0 through $grid-columns {
      @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-xs-0
        .offset#{$infix}-#{$i} {
          @include make-col-modifier(offset, $i, $grid-columns)
        }
      }
    }
  }
}


.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}
