.testimonials {
  background-color: $brown;
  border-top: 1px solid $dark-brown;

  .testimonial-slider {
    margin: 15px 0;

    @include desktop {
      margin: 15px 0 0;
    }

    .testimonial {
      padding: 0 15px;

      @include tablet {
        padding: 0 30px;
      }

      .image {
        width: 100%;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include tablet {
          float: left;
          width: 250px;
          height: 200px;
          margin: 0 40px 0 0;
        }

        @include laptop {
          width: 375px;
          height: 280px;
        }
      }

      .content-block {
        margin: 15px 0 0;
        text-align: center;

        @include tablet {
          margin: 30px 0 0;
          text-align: left;
        }

        @include laptop {
          margin: 50px 0 0;
        }

        p {
          color: $brand-primary;
        }

        .content {
          margin: 0 0 20px;
        }

        .author {
          text-transform: uppercase;
        }
      }
    }
  }
}


.footer, .linksCopy {
  p, a {
    color: $white;
    line-height: 24px;
  }
}


.footer {
  padding: 40px 0;
  background-image: url('../images/bg_footer.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  text-align: center;

  @include tablet {
    padding: 60px 0;
  }

  .logo {
    width: 300px;

    @include tablet {
      width: 404px;
    }
  }

  .logo {
    margin: 0 0 35px;
  }

  .address {
    font-weight: 400;
  }

  .social-links {
    font-size: 20px;
  }

  .footer-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: block;
      margin: 5px 0;

      @include tablet {
        display: inline-block;
        margin: 5px 10px;
      }

      @include laptop {
        margin: 5px 20px;
      }

      a {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        @include letter-spacing(100);

        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }
  }

  .btn {
    width: 290px;
    margin: 25px 0 0;

    @include laptop {
      margin: 40px 0 0;
    }
  }
}


.linksCopy {
  padding: 5px 0;
  background-color: $dark-gray;

  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    text-align: center;
    @include letter-spacing(0);

    @include tablet {
      font-size: 14px;
    }

    @include desktop {
      text-align: left;
    }
  }

  .right-text {

    @include desktop {
      text-align: right;
    }
  }
}
