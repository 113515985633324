.inner-content {
  padding: 30px 0;
  background-color: $light-brown;

  @include tablet {
    padding: 50px 0;
  }

  h1 {
    margin: 0 0 20px;

    @include tablet {
      margin: 0 0 30px;
    }
  }

  h2 {
    margin: 5px 0 10px;

    @include tablet {
      margin: 5px 0 15px;
    }
  }

  .alignnone {
    width: 100%;
  }

  .alignleft, .alignright {
    width: 100%;

    @include tablet {
      width: 350px;
    }
  }

  .alignleft {
    margin: 0 0 15px;

    @include tablet {
      margin: 0 25px 5px 0;
    }
  }
}


.breadcrumbs {
  display: block;
  padding: 11px 10px;
  margin: 0 0 30px;
  background-color: $brown;
  font-size: 13px;

  @include phablet {
    font-size: 14px;
    margin: 0 0 40px;
  }

  span[property="itemListElement"] {
    margin: 0 3px;

    @include phablet {
      margin: 0 5px;
    }
  }
}


.footer-map {
  margin: 15px 0 30px;

  #map_canvas {
    height: 300px;

    @include laptop {
      height: 425px;
    }
  }
}


.contact-form {

  @include desktop {
    padding: 0 30px 0 0;
  }
}
