// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;
  }

  .gfield {
    margin-bottom: 20px;

    label {
      font-weight: 700;
    }

    input,
    textarea {
      border: none;
      padding: 10px 10px;
      width: 100%;
      font-size: 16px;
    }

    textarea {
      height: 240px;
    }
  }

  .gform_footer {

    input[type=submit] {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend .btn;
      background-color: $brand-primary;
      color: $off-white;
      cursor: pointer;

      &:hover {
        background-color: lighten($brand-primary, 15);
      }
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}
