.entry-content {
  overflow: auto;
}

.gallery-section {
  margin: 15px 0 0;
}

.photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0 -10px;

    img {
      display: none;
    }

    a {
      @include flex(0,0,50%);
      padding: 10px;

      @include phablet {
        @include flex(0,0,50%);
      }

      @include desktop {
        @include flex(0,0,33.3%);
      }
    }

    .gallery-image {
      background-size: cover;
      background-position: left bottom;
      height: 125px;
      position: relative;

      @include phablet {
        height: 230px;
      }

      &:before {
        content: '\f00e';
        @include stretch;
        font-family: FontAwesome;
        font-size: 28px;
        opacity: 0;
        transition: $base-transition;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover:before {
        background-color: rgba(0,0,0,.3);
        opacity: 1;
      }
    }

    #launchGallery {
      cursor: pointer;
      text-decoration: none;

      .text {
        font-size: 18px;
        opacity: 1;
        transition: $base-transition;
        text-align: center;

        @include phablet {
          font-size: 22px;
        }
      }

      &:hover {
        .text {
          opacity: 0.1;
        }
      }
    }

    .gallery-more {
      display: flex;
      height: 125px;
      justify-content: center;
      align-items: center;
      background-color: $brand-primary;
      color: $off-white;

      @include phablet {
        height: 230px;
      }
    }
  }
