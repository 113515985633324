.about-section {
  background-color: $light-brown;

  .heading {
    font-size: 19px;
    line-height: 23px;
    @include letter-spacing(50);

    @include tablet {
      font-size: 21px;
      line-height: 25px;
    }
  }

  .content-container {

    @include hd {
      margin: 0 -35px 0 0;
    }

    p:last-of-type {
      margin: 0;
    }
  }

  .featured-image {
    width: 100%;
    margin: 0 0 15px;

    @include laptop {
      float: right;
      width: auto;
      margin: 0;
    }
  }
}


.our-services-section {
  background-color: $brown;
  border-top: 1px solid $dark-brown;
  text-align: center;
}


.project-portfolio-section {
  text-align: center;
  background-color: $light-brown;
  border-top: 1px solid $dark-brown;

  .btn {
    margin: 25px 0 0;

    @include desktop {
      margin: 40px 0 0;
    }
  }
}
